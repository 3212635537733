import React from 'react';
import './EventsShowcase.css';

const EventsShowcase = () => {
  const events = [
    { title: 'Birthdays', image: 'https://wedding-photos-apps.s3.us-west-1.amazonaws.com/party.jpg' },
    { title: 'Weddings', image: 'https://wedding-photos-apps.s3.us-west-1.amazonaws.com/wedding.jpg' },
    { title: 'Memorials', image: 'https://wedding-photos-apps.s3.us-west-1.amazonaws.com/gravestone.jpg' },
    { title: 'Business Events', image: 'https://wedding-photos-apps.s3.us-west-1.amazonaws.com/singer.jpg' },
  ];

  return (
    <section className="events-section">
      <h2>Used for Any Event</h2>
      <div className="events-grid">
        {events.map((event, index) => (
          <div key={index} className="event-card">
            <img src={event.image} alt={event.title} />
            <h3>{event.title}</h3>
          </div>
        ))}
      </div>
    </section>
  );
};

export default EventsShowcase;


// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.steps-section {
  padding: 60px 20px;
  background-color: #00143b;
  color: #f5f5e9;
  text-align: center;
}

.steps-section h2 {
  font-size: 32px;
  margin-bottom: 20px;
}

.steps-section h3 {
  font-size: 26px;
  margin-bottom: 40px;
}

.steps {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.step {
  width: 30%;
  margin: 20px 0;
}

.step h4 {
  font-size: 36px;
}

.step p {
  font-size: 16px;
  line-height: 1.6;
}

`, "",{"version":3,"sources":["webpack://./src/StepsSection.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,6BAA6B;EAC7B,eAAe;AACjB;;AAEA;EACE,UAAU;EACV,cAAc;AAChB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".steps-section {\n  padding: 60px 20px;\n  background-color: #00143b;\n  color: #f5f5e9;\n  text-align: center;\n}\n\n.steps-section h2 {\n  font-size: 32px;\n  margin-bottom: 20px;\n}\n\n.steps-section h3 {\n  font-size: 26px;\n  margin-bottom: 40px;\n}\n\n.steps {\n  display: flex;\n  justify-content: space-around;\n  flex-wrap: wrap;\n}\n\n.step {\n  width: 30%;\n  margin: 20px 0;\n}\n\n.step h4 {\n  font-size: 36px;\n}\n\n.step p {\n  font-size: 16px;\n  line-height: 1.6;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

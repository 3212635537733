import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import UploadForm from './UploadForm';
import EditEvent from './EditEvent';
import EventQRCode from './EventQRCode';
import EventHero from './EventHero';
import PhotoGrid from './PhotoGrid';
import RegisterModal from './RegisterModal';
import LoginModal from './LoginModal'; // Import the LoginModal component
import API from './api';

function EventPage() {
  const { eventId } = useParams();
  const [photos, setPhotos] = useState([]);
  const [eventDetails, setEventDetails] = useState(null);
  const [error, setError] = useState(null);
  const [showQRCode, setShowQRCode] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false); // State for login modal
  const [isAuthenticated, setIsAuthenticated] = useState(false); // State for authentication
  const [currentUser, setCurrentUser] = useState(null); // Store current user

  useEffect(() => {
    API.get(`${process.env.REACT_APP_API_URL}/event/${eventId}`)
      .then(response => setEventDetails(response.data))
      .catch(error => {
        console.error('Error fetching event details:', error);
        setError('Event not found.');
      });

    API.get(`${process.env.REACT_APP_API_URL}/photos/${eventId}`)
      .then(response => setPhotos(response.data))
      .catch(error => {
        console.error('Error fetching photos:', error);
        setError('Error fetching photos.');
      });

    // Check if the user is authenticated
    API.get(`${process.env.REACT_APP_API_URL}/session`)
      .then(response => {
        if (response.data.isAuthenticated) {
          setIsAuthenticated(true);
          setCurrentUser(response.data.user);
        }
      })
      .catch(error => {
        console.error(error);
      });
  }, [eventId]);

  useEffect(() => {
    if (eventDetails && eventDetails.name) {
      document.title = eventDetails.name;
    } else {
      document.title = 'Event'; // Fallback title if eventDetails is not yet loaded
    }
  }, [eventDetails]);  

  const handleShowQRCode = () => {
    setShowQRCode(!showQRCode);
  };

  const handleRegister = (resp) => {
    setIsAuthenticated(true);
    setCurrentUser(resp);
  };

  const handleLogin = (resp) => {
    setIsAuthenticated(true);
    setCurrentUser(resp);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
    setCurrentUser(null);
  };

  const openLoginModal = () => {
    setShowLoginModal(true);  // Open the modal
  };

  const closeLoginModal = () => {
    setShowLoginModal(false);  // Close the modal and reset state
  };

  const handleOpenSlideshow = () => {
    const slideshowUrl = `/photos/${eventId}`;
    window.open(slideshowUrl, '_blank');
  };
  
  const handleDeletePhoto = (photoId) => {
    API.delete(`/photos/${photoId}`)
      .then(() => {
        // Remove the photo from state
        setPhotos(photos.filter(photo => photo._id !== photoId));
      })
      .catch(error => {
        console.error('Error deleting photo:', error);
        alert('Error deleting photo');
      });
  };

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  if (!eventDetails) {
    return <div>Loading...</div>;
  }
//    <div className="EventPage" style={{ backgroundColor: eventDetails.backgroundColor }}>
  const styleBlock = `
    @font-face {
      font-family: 'Kestler';
      src: url('https://wedding-photos-apps.s3.us-west-1.amazonaws.com/Kunstler.otf') format('otf'),
           url('https://wedding-photos-apps.s3.us-west-1.amazonaws.com/Kunstler.woff') format('woff'),
           url('https://wedding-photos-apps.s3.us-west-1.amazonaws.com/Kunstler.woff2') format('woff2'),
      font-weight: normal;
      font-style: normal;
  }
  `;

  return (
    <div className="EventPage">
      <style>{styleBlock}</style>

      {eventDetails.logoUrl && (
        <img src={eventDetails.logoUrl} alt="Event Logo" className="event-logo" />
      )}

      <div className="event-actions">
        {/* <button onClick={() => setShowQRCode(!showQRCode)}>
          {showQRCode ? 'Hide QR Code' : 'Share'}
        </button>
        <button onClick={() => setShowEditForm(!showEditForm)}>
          {showEditForm ? 'Close Edit' : 'Edit Event'}
        </button> */}

        {isAuthenticated ? (
          <>
            <span>Welcome, {currentUser.name}</span>
            <button onClick={handleLogout}>Logout</button>
          </>
        ) : (
          <>
            <button onClick={() => setShowRegisterModal(true)}>Sign Up</button>
            <button onClick={openLoginModal}>Login</button>
          </>
        )}
      </div>

      <EventHero
        eventDetails={eventDetails}
        onEdit={() => setShowEditForm(true)}
        onDelete={() => console.log('Delete event')}
        onShowQRCode={handleShowQRCode}  // Pass the handler to show QR code
        handleSlideshow={handleOpenSlideshow}
      />
      
      <div className="event-content">
        {showQRCode && <EventQRCode eventId={eventId} />}
        {showEditForm && (
          <EditEvent
            eventId={eventId}
            onEventUpdated={(updatedEvent) => setEventDetails(updatedEvent)}
          />
        )}
        <UploadForm onUpload={newPhoto => setPhotos([newPhoto, ...photos])} eventId={eventId} currentUser={currentUser} />
        <PhotoGrid
          photos={photos}
          currentUser={currentUser}
          eventCreatorId={eventDetails.creatorId}
          onDeletePhoto={handleDeletePhoto}
        />
      </div>

      {/* Register Modal */}
      {showRegisterModal && (
        <RegisterModal
          onClose={() => setShowRegisterModal(false)}
          onRegister={handleRegister}
        />
      )}

      {/* Login Modal */}
      {showLoginModal && (
        <LoginModal
          onClose={closeLoginModal}
          onLogin={handleLogin}
        />
      )}
    </div>
  );
}

export default EventPage;

import React, { useState, useEffect } from 'react';
import axios from 'axios';

function EditEvent({ eventId, onEventUpdated }) {
  const [eventData, setEventData] = useState(null);
  const [updating, setUpdating] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch current event data
    axios.get(`${process.env.REACT_APP_API_URL}/event/${eventId}`)
      .then(response => setEventData(response.data))
      .catch(error => {
        console.error('Error fetching event details:', error);
        setError('Failed to load event details.');
      });
  }, [eventId]);

  const handleUpdateEvent = (e) => {
    e.preventDefault();
    setUpdating(true);
    setError(null);

    const { name, backgroundColor, logoUrl } = eventData;

    axios
      .put(`${process.env.REACT_APP_API_URL}/event/${eventId}`, { name, backgroundColor, logoUrl })
      .then((response) => {
        if (onEventUpdated) {
          onEventUpdated(response.data.event);
        }
      })
      .catch((err) => {
        console.error('Error updating event:', err);
        setError('Failed to update event. Please try again.');
      })
      .finally(() => setUpdating(false));
  };

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  if (!eventData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="edit-event-form">
      <form onSubmit={handleUpdateEvent}>
        <input
          type="text"
          placeholder="Event Name"
          value={eventData.name}
          onChange={(e) => setEventData({ ...eventData, name: e.target.value })}
          required
        />
        <input
          type="color"
          value={eventData.backgroundColor}
          onChange={(e) => setEventData({ ...eventData, backgroundColor: e.target.value })}
          title="Choose Event Background Color"
        />
        <input
          type="text"
          placeholder="Logo URL (Optional)"
          value={eventData.logoUrl}
          onChange={(e) => setEventData({ ...eventData, logoUrl: e.target.value })}
        />
        <button type="submit" disabled={updating}>
          {updating ? 'Updating...' : 'Update Event'}
        </button>
      </form>
    </div>
  );
}

export default EditEvent;

import { QRCodeSVG } from 'qrcode.react';

function EventQRCode({ eventId }) {
  const eventUrl = `${window.location.origin}/event/${eventId}`;

  return (
    <div className="qr-code">
      <QRCodeSVG value={eventUrl} size={200} />
    </div>
  );
}

export default EventQRCode;

import React, { useState } from 'react';
import './EventHero.css';

const EventHero = ({ eventDetails, onEdit, onDelete, onShowQRCode, handleSlideshow }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };
  
  return (
    <section className="event-header" style={{ backgroundColor: eventDetails.backgroundColor }}>
      <div className="event-header-content">
        <h1 style={{ fontFamily: eventDetails.fontFamily }}>{eventDetails.name}</h1>
        <h2 className="hero-date">{eventDetails.eventDate}</h2>
        <p className="hero-caption">{eventDetails.caption}</p>
        <p className="hero-hashtag">#{eventDetails.hashTag.replace(/^#/,'')}</p>
        <div className="event-buttons">
          <button className="photo-wall-btn" onClick={handleSlideshow}>Slideshow</button>
          <button className="more-actions-btn" onClick={toggleDropdown}>
            More actions &#x25BC;
            {dropdownVisible && (
              <ul className="dropdown-menu">
                {/* <li onClick={onEdit}>Edit Event</li>
                <li onClick={onDelete}>Delete Event</li> */}
                <li onClick={onShowQRCode}>Share QR Code</li>
              </ul>
            )}
          </button>
        </div>
        {/* {eventDetails.heroImage && (
        <div className="event-hero-image">
          <img src={eventDetails.heroImage} alt="Event Hero Image" />
        </div>
      )} */}
      </div>
    </section>
  );
};

export default EventHero;
import React from 'react';
import './StepsSection.css';

const StepsSection = () => {
  return (
    <section className="steps-section">
      <h2>Have an Event?</h2>
      <h3>As Simple as 1, 2, 3</h3>
      <div className="steps">
        <div className="step">
          <h4>1.</h4>
          <p>In just a few clicks, you can create a digital album. Start by adding your own photos and videos, or share it right away for others to join in and contribute.</p>
        </div>
        <div className="step">
          <h4>2.</h4>
          <p>Share your album with a simple link or print a QR code for easy scanning and access. No registration or app download needed!</p>
        </div>
        <div className="step">
          <h4>3.</h4>
          <p>View uploaded photos and videos, read captions, download individual files as needed, or save the entire album with a single click and enjoy!</p>
        </div>
      </div>
    </section>
  );
};

export default StepsSection;


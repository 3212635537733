import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import EventPage from './EventPage';
import HomePage from './HomePage'; // Import the new HomePage component
import NotFoundPage from './NotFoundPage'; // Optional: for handling 404 errors
import PhotoSlideshow from './PhotoSlideshow';
import "./index.css";

function App() {
  useEffect(() => {
    // Check if the app is running on localhost
    const isLocalhost = Boolean(
      window.location.hostname === 'localhost' ||
        window.location.hostname === '[::1]' ||
        window.location.hostname.match(
          /^127(?:\.(?:25[0-5]|2[0-4]\d|[01]?\d\d?)){3}$/
        )
    );

    // Redirect to HTTPS if not on localhost and not already on HTTPS
    if (!isLocalhost && window.location.protocol !== 'https:') {
      window.location.href =
        'https://' + window.location.host + window.location.pathname + window.location.search;
    }
  }, []);

  return (
    <Router>
      <Routes>
        {/* Default route for when no specific event is being viewed */}
        <Route path="/" element={<HomePage />} />
        {/* Route for viewing photos for a specific event */}
        <Route path="/event/:eventId" element={<EventPage />} />
        {/* Route for slideshow for a specific event */}
        <Route path="/photos/:eventId" element={<PhotoSlideshow />} />
        {/* Optional: Catch-all route for undefined paths */}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
}

export default App;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.file-input-label {
    display: inline-block;
    background-color: #BC7555;
    color: white;
    padding: 15px 30px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    height: 18px;
    line-height: 1.4;
  }
  
.file-input {
    display: none; /* Hides the default file input */
}

button[type="submit"], button[type="button"] {
    padding: 15px 30px;
    font-size: 16px;
    background-color: #BC7555;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

button[type="submit"]:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}`, "",{"version":3,"sources":["webpack://./src/UploadForm.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;IACf,eAAe;IACf,YAAY;IACZ,gBAAgB;EAClB;;AAEF;IACI,aAAa,EAAE,iCAAiC;AACpD;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,mBAAmB;AACvB","sourcesContent":[".file-input-label {\n    display: inline-block;\n    background-color: #BC7555;\n    color: white;\n    padding: 15px 30px;\n    border-radius: 5px;\n    cursor: pointer;\n    font-size: 16px;\n    height: 18px;\n    line-height: 1.4;\n  }\n  \n.file-input {\n    display: none; /* Hides the default file input */\n}\n\nbutton[type=\"submit\"], button[type=\"button\"] {\n    padding: 15px 30px;\n    font-size: 16px;\n    background-color: #BC7555;\n    color: white;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n}\n\nbutton[type=\"submit\"]:disabled {\n    opacity: 0.6;\n    cursor: not-allowed;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useState } from 'react';
import axios from 'axios';

function CreateEvent({ onEventCreated }) {
  const [eventName, setEventName] = useState('');
  const [backgroundColor, setBackgroundColor] = useState('#ffffff');
  const [logoUrl, setLogoUrl] = useState('');
  const [creating, setCreating] = useState(false);
  const [error, setError] = useState(null);

  const handleCreateEvent = (e) => {
    e.preventDefault();
    setCreating(true);
    setError(null);

    const eventData = { name: eventName, backgroundColor, logoUrl };

    axios
      .post(`${process.env.REACT_APP_API_URL}/create-event`, eventData)
      .then((response) => {
        setEventName('');
        setBackgroundColor('#ffffff');
        setLogoUrl('');
        if (onEventCreated) {
          onEventCreated(response.data.eventId);
        }
      })
      .catch((err) => {
        console.error('Error creating event:', err);
        setError('Failed to create event. Please try again.');
      })
      .finally(() => setCreating(false));
  };

  return (
    <div className="create-event-form">
      {error && <div className="error-message">{error}</div>}
      <form onSubmit={handleCreateEvent}>
        <input
          type="text"
          placeholder="Event Name"
          value={eventName}
          onChange={(e) => setEventName(e.target.value)}
          required
        />
        <input
          type="color"
          value={backgroundColor}
          onChange={(e) => setBackgroundColor(e.target.value)}
          title="Choose Event Background Color"
        />
        <input
          type="text"
          placeholder="Logo URL (Optional)"
          value={logoUrl}
          onChange={(e) => setLogoUrl(e.target.value)}
        />
        <button type="submit" disabled={creating}>
          {creating ? 'Creating...' : 'Create Event'}
        </button>
      </form>
    </div>
  );
}

export default CreateEvent;


import React from 'react';
import './HeroSection.css';

const HeroSection = () => {
  return (
    <section className="hero">
      <h1>Effortless and Enjoyable Photo Sharing for Every Occasion</h1>
      <p>
        Welcome to Memora Lane, where capturing and sharing your photos and videos for any event is a breeze! No app downloads or guest sign-ups needed—simply create an album and start sharing with ease.
      </p>
    </section>
  );
};

export default HeroSection;


// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hero {
  text-align: center;
  padding: 40px 20px 80px;
  background-color: #fdf6e4;
  color: #4a3d48;
}

.hero h1 {
  font-size: 36px;
  margin-bottom: 20px;
}

.hero p {
  font-size: 18px;
  color: #333;
  max-width: 600px;
  margin: 0 auto;
}

`, "",{"version":3,"sources":["webpack://./src/HeroSection.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,uBAAuB;EACvB,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,WAAW;EACX,gBAAgB;EAChB,cAAc;AAChB","sourcesContent":[".hero {\n  text-align: center;\n  padding: 40px 20px 80px;\n  background-color: #fdf6e4;\n  color: #4a3d48;\n}\n\n.hero h1 {\n  font-size: 36px;\n  margin-bottom: 20px;\n}\n\n.hero p {\n  font-size: 18px;\n  color: #333;\n  max-width: 600px;\n  margin: 0 auto;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

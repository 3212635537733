import React, { useState } from 'react';
import Modal from 'react-modal';
import './Header.css';
import CreateEvent from './CreateEvent';

Modal.setAppElement('#root');  // Set the root element for accessibility

const Header = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <header className="header">
      <div className="logo">
        <img src="https://wedding-photos-apps.s3.us-west-1.amazonaws.com/logo.png" alt="Memora Lane" />
      </div>
      <div className="header-buttons">
        <button className="create-event-btn" onClick={openModal}>
          Create Event
        </button>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Create Event"
          className="modal-content"
          overlayClassName="modal-overlay"
        >
          <CreateEvent onEventCreated={newEventId => {
            closeModal();
            // Handle navigation or post-creation actions
          }} />
          <button className="close-modal-btn" onClick={closeModal}>Close</button>
        </Modal>
        <button className="sign-in-btn">Sign In</button>
      </div>
    </header>
  );
};

export default Header;


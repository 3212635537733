// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  padding: 40px 20px;
  background-color: #00143b;
  color: white;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.footer-nav ul {
  list-style-type: none;
  padding: 0;
}

.footer-nav ul li {
  margin: 10px 0;
}

.footer-bottom {
  margin-top: 20px;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/Footer.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,uBAAuB;AACzB;;AAEA;EACE,qBAAqB;EACrB,UAAU;AACZ;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;AACpB","sourcesContent":[".footer {\n  padding: 40px 20px;\n  background-color: #00143b;\n  color: white;\n}\n\n.footer-content {\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-start;\n}\n\n.footer-nav ul {\n  list-style-type: none;\n  padding: 0;\n}\n\n.footer-nav ul li {\n  margin: 10px 0;\n}\n\n.footer-bottom {\n  margin-top: 20px;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

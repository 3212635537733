import React, { useState } from 'react';
import Masonry from 'react-masonry-css';
import './PhotoGrid.css';
import PhotoModal from './PhotoModal';

const PhotoGrid = ({ photos, currentUser, eventCreatorId, onDeletePhoto }) => {
  const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(null);

  const handleImageClick = (index) => {
    setSelectedPhotoIndex(index);
  };

  const handleNext = () => {
    setSelectedPhotoIndex((selectedPhotoIndex + 1) % photos.length);
  };

  const handlePrevious = () => {
    setSelectedPhotoIndex((selectedPhotoIndex - 1 + photos.length) % photos.length);
  };

  const closeModal = () => {
    setSelectedPhotoIndex(null);
  };

  const breakpointColumnsObj = {
    default: 3,
    1100: 3,
    700: 2,
    500: 1,
  };

  return (
    <>
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="photo-grid"
        columnClassName="photo-grid-column"
      >
        {photos.map((photo, index) => (
          <div key={index} className={photo.caption ? ("photo-item captioned") : ("photo-item")} onClick={() => handleImageClick(index)}>
            <img src={photo.url} alt={`photo-${index}`} onClick={() => handleImageClick(index)} />
            {photo.caption && (
                <p className="photo-caption">
                  {photo.caption}
                  {photo.caption && (<br />)}
                  {photo.author && (
                    <span className="photo-author">- {photo.author}</span>
                  )}
                </p>
            )}
            {(currentUser && (photo.uploaderId === currentUser.userId || eventCreatorId === currentUser.userId)) && (
              <button onClick={(e) => { e.stopPropagation(); onDeletePhoto(photo._id); }}>Delete</button>
            )}
          </div>
        ))}
      </Masonry>

      {selectedPhotoIndex !== null && (
        <PhotoModal
          photo={photos[selectedPhotoIndex]}
          isOpen={selectedPhotoIndex !== null}
          onClose={closeModal}
          onNext={handleNext}
          onPrevious={handlePrevious}
        />
      )}
    </>
  );
};

export default PhotoGrid;

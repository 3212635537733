// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* PhotoSlideshow.css */
.slideshow-container {
    position: relative;
    max-width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: #000;
  }
  
  .slide {
    object-fit: contain;
    transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}

.slide-image {
    max-width: 80vw;
}
  .prev, .next {
    position: absolute;
    top: 50%;
    background-color: rgba(0,0,0,0.5);
    color: white;
    font-size: 24px;
    padding: 16px;
    cursor: pointer;
    border: none;
    border-radius: 50%;
    z-index: 10;
  }
  
  .prev {
    left: 10px;
  }
  
  .next {
    right: 10px;
  }
  
  .prev:hover, .next:hover {
    background-color: rgba(0,0,0,0.8);
  }
  
  .fade-in {
    opacity: 1;
    transform: translateX(0);
  }
  
  .fade-out {
    opacity: 0;
    transform: translateX(-100%);
  }`, "",{"version":3,"sources":["webpack://./src/PhotoSlideshow.css"],"names":[],"mappings":"AAAA,uBAAuB;AACvB;IACI,kBAAkB;IAClB,eAAe;IACf,aAAa;IACb,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;IAChB,sBAAsB;EACxB;;EAEA;IACE,mBAAmB;IACnB,4DAA4D;AAChE;;AAEA;IACI,eAAe;AACnB;EACE;IACE,kBAAkB;IAClB,QAAQ;IACR,iCAAiC;IACjC,YAAY;IACZ,eAAe;IACf,aAAa;IACb,eAAe;IACf,YAAY;IACZ,kBAAkB;IAClB,WAAW;EACb;;EAEA;IACE,UAAU;EACZ;;EAEA;IACE,WAAW;EACb;;EAEA;IACE,iCAAiC;EACnC;;EAEA;IACE,UAAU;IACV,wBAAwB;EAC1B;;EAEA;IACE,UAAU;IACV,4BAA4B;EAC9B","sourcesContent":["/* PhotoSlideshow.css */\n.slideshow-container {\n    position: relative;\n    max-width: 100%;\n    height: 100vh;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    overflow: hidden;\n    background-color: #000;\n  }\n  \n  .slide {\n    object-fit: contain;\n    transition: opacity 1s ease-in-out, transform 1s ease-in-out;\n}\n\n.slide-image {\n    max-width: 80vw;\n}\n  .prev, .next {\n    position: absolute;\n    top: 50%;\n    background-color: rgba(0,0,0,0.5);\n    color: white;\n    font-size: 24px;\n    padding: 16px;\n    cursor: pointer;\n    border: none;\n    border-radius: 50%;\n    z-index: 10;\n  }\n  \n  .prev {\n    left: 10px;\n  }\n  \n  .next {\n    right: 10px;\n  }\n  \n  .prev:hover, .next:hover {\n    background-color: rgba(0,0,0,0.8);\n  }\n  \n  .fade-in {\n    opacity: 1;\n    transform: translateX(0);\n  }\n  \n  .fade-out {\n    opacity: 0;\n    transform: translateX(-100%);\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// src/HomePage.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CreateEvent from './CreateEvent';
import Header from './Header';
import HeroSection from './HeroSection';
import StepsSection from './StepsSection';
import EventsShowcase from './EventsShowcase';
import Footer from './Footer';


function HomePage() {
  const [eventId, setEventId] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (eventId.trim() !== '') {
      navigate(`/event/${eventId.trim()}`);
    }
  };

  const handleEventCreated = (newEventId) => {
    // Navigate to the newly created event page
    navigate(`/event/${newEventId}`);
  };

  const styleBlock = `
    @font-face {
      font-family: 'Gagalin';
      src: url('https://wedding-photos-apps.s3.us-west-1.amazonaws.com/Gagalin-Regular.otf') format('otf'),
             url('https://wedding-photos-apps.s3.us-west-1.amazonaws.com/Gagalin-Regular.woff') format('woff');
      font-weight: normal;
      font-style: normal;
    }
  `;

  return (
    <div className="HomePage">
      <style>
        {styleBlock}
      </style>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link href="https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap" rel="stylesheet" />
      <Header />
      <HeroSection />
      <StepsSection />
      <EventsShowcase />
      <Footer />
    </div>
  );
}

export default HomePage;

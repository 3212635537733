import { useEffect } from 'react';

/**
 * Custom hook to dynamically load and remove modal-specific stylesheets.
 *
 * @param {boolean} isOpen - Whether the modal is open.
 * @param {string} stylesheetPath - Path to the modal stylesheet.
 */
export const useModalStyles = (isOpen, stylesheetPath) => {
  useEffect(() => {
    // Function to add the modal stylesheet
    const addModalStylesheet = () => {
      const link = document.createElement('link');

      link.rel = 'stylesheet';
      link.href = stylesheetPath;
      link.id = 'modal-styles'; // Assign an ID to easily find/remove the stylesheet

      document.head.appendChild(link);
    };

    // Function to remove the modal stylesheet
    const removeModalStylesheet = () => {
      const link = document.getElementById('modal-styles');

      if (link) {
        document.head.removeChild(link);
      }
    };

    // If modal is open, add the stylesheet, otherwise remove it
    if (isOpen) {
      addModalStylesheet();
    } else {
      removeModalStylesheet();
    }

    // Cleanup function to remove the stylesheet when the component unmounts
    return () => {
      removeModalStylesheet();
    };
  }, [isOpen, stylesheetPath]);
};

import React, { useState } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import { useModalStyles } from './useModalStyles';

function LoginModal({ onClose, onLogin }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(true);

  // Use the custom hook to manage the modal stylesheet
  useModalStyles(modalIsOpen, '/LoginModal.css');  // Path to the specific modal styles

  const closeModal = () => setModalIsOpen(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    const loginData = { email, password };

    axios.post(`${process.env.REACT_APP_API_URL}/login`, loginData)
      .then(response => {
        const token = response.data.token;
        localStorage.setItem('token', token); // Store token in localStorage
        onLogin(response.data.user); // Update state with user info if needed
        onClose();
      })
      .catch(err => {
        setError('Login failed. Please try again.');
        setLoading(false);
      });
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={onClose}
      className="modal-content"
      overlayClassName="modal-overlay"
    >
      <h2>Login</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        {error && <p className="error-message">{error}</p>}
        <button type="submit" disabled={loading}>
          {loading ? 'Logging in...' : 'Login'}
        </button>
        <button type="button" onClick={onClose}>Close</button>
      </form>
    </Modal>
  );
}

export default LoginModal;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.photo-grid {
    display: flex;
    margin-top: 40px;
}

.photo-item {
    position: relative;
}
  
.photo-item img {
    max-width: 200px;
    border: 1px solid #f5f0e4;
    padding: 5px;
    border-radius: 5px;
}
  
.photo-item button {
    position: absolute;
    bottom: 10px;
    right: 10px;
}
  
.photo-grid-column {
    background-clip: padding-box;
}
  
.photo-item {
    margin-bottom: 20px;
    padding: 0 10px;
    text-align: center;
}

.photo-item.captioned img {
    border-radius: 5px 5px 0 0;
}
.photo-item img {
    width: 100%;
    border-radius: 10px;
    cursor: pointer;
}

.photo-caption {
    font-size: 16px;
    margin: 0;
    padding: 5px;
    text-align: left;
    background: #f5f0e4;
    border-radius: 0 0 5px 5px;
    position: relative;
    top: -4px;
}
  
.photo-author {
    font-size: 14px;
    color: #888;
}
 
.modal-content .photo-caption {
    width: 80%;
    margin: 0 auto;
}`, "",{"version":3,"sources":["webpack://./src/PhotoGrid.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,mBAAmB;IACnB,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,0BAA0B;AAC9B;AACA;IACI,WAAW;IACX,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,SAAS;IACT,YAAY;IACZ,gBAAgB;IAChB,mBAAmB;IACnB,0BAA0B;IAC1B,kBAAkB;IAClB,SAAS;AACb;;AAEA;IACI,eAAe;IACf,WAAW;AACf;;AAEA;IACI,UAAU;IACV,cAAc;AAClB","sourcesContent":[".photo-grid {\n    display: flex;\n    margin-top: 40px;\n}\n\n.photo-item {\n    position: relative;\n}\n  \n.photo-item img {\n    max-width: 200px;\n    border: 1px solid #f5f0e4;\n    padding: 5px;\n    border-radius: 5px;\n}\n  \n.photo-item button {\n    position: absolute;\n    bottom: 10px;\n    right: 10px;\n}\n  \n.photo-grid-column {\n    background-clip: padding-box;\n}\n  \n.photo-item {\n    margin-bottom: 20px;\n    padding: 0 10px;\n    text-align: center;\n}\n\n.photo-item.captioned img {\n    border-radius: 5px 5px 0 0;\n}\n.photo-item img {\n    width: 100%;\n    border-radius: 10px;\n    cursor: pointer;\n}\n\n.photo-caption {\n    font-size: 16px;\n    margin: 0;\n    padding: 5px;\n    text-align: left;\n    background: #f5f0e4;\n    border-radius: 0 0 5px 5px;\n    position: relative;\n    top: -4px;\n}\n  \n.photo-author {\n    font-size: 14px;\n    color: #888;\n}\n \n.modal-content .photo-caption {\n    width: 80%;\n    margin: 0 auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="logo">
          <h3>Memora Lane</h3>
          <p>SNAP • SHARE • ENJOY</p>
        </div>
      </div>
      <div className="footer-bottom">
        <p>Terms & Support</p>
      </div>
    </footer>
  );
};

export default Footer;


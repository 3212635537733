import React, { useState } from 'react';
import Modal from 'react-modal';
import { useModalStyles } from './useModalStyles';  // Import the custom hook

const PhotoModal = ({ photo, isOpen, onClose, onNext, onPrevious }) => {
  const handleOverlayClick = (e) => {
    if (e.target.classList.contains('modal-overlay')) {
      onClose();
    }
  };

  useModalStyles(isOpen, '/PhotoModal.css');  // Use the correct path for your styles

  if (!isOpen) return null;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="modal-content"
      overlayClassName="modal-overlay"
    >
      <div className="modal-body">
        <img src={photo.url} alt="Selected" className="modal-image" />
        {photo.caption && (
                <p className="photo-caption">
                  {photo.caption} <br />
                  {photo.author && (
                    <span className="photo-author">- {photo.author}</span>
                  )}
                </p>
            )}

        <div className="modal-navigation">
          <button onClick={onPrevious}>Previous</button>
          <button onClick={onNext}>Next</button>
        </div>
      </div>
    </Modal>
  );
};

export default PhotoModal;

import React, { useState, useRef } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import API from './api';
import { useModalStyles } from './useModalStyles';  // Import the custom hook
require("./UploadForm.css");

function UploadForm({ onUpload, eventId, currentUser }) {
  const [file, setFile] = useState(null);
  const [caption, setCaption] = useState('');
  const [uploading, setUploading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const fileInputRef = useRef(null);

  // Use the custom hook to dynamically load and remove modal styles
  useModalStyles(modalIsOpen, '/UploadFormModal.css');  // Use the correct path for your styles

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setModalIsOpen(true); // Open the modal when a file is selected
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    const trimmedCaption = caption.trim();

    if (!file) return;

    setUploading(true);
    const formData = new FormData();
    formData.append('photo', file);
    formData.append('caption', trimmedCaption);

    if (currentUser && currentUser.name) {
      formData.append('author', currentUser.name);
    }

    API.post(`${process.env.REACT_APP_API_URL}/upload/${eventId}`, formData)
      .then(response => {
        onUpload(response.data);
        setFile(null);
        setCaption('');
        fileInputRef.current.value = '';  // Clear file input
        setModalIsOpen(false);  // Close the modal after successful upload
      })
      .catch(error => console.error('Error uploading file:', error))
      .finally(() => setUploading(false));
  };

  const closeModal = () => {
    setFile(null);
    setModalIsOpen(false);  // Close the modal without uploading
  };

  return (
    <div>
      <form>
        <label className="file-input-label">
          Choose Memory
          <input
            type="file"
            onChange={handleFileChange}
            ref={fileInputRef}
            className="file-input"
          />
        </label>
      </form>

      {/* Modal for photo preview and caption input */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <div className="modal-body">
          <h2>Preview and Caption</h2>
          {file && (
            <div>
              {/* Display the image preview */}
              <img src={URL.createObjectURL(file)} alt="Preview" className="image-preview" />
              
              {/* Caption text area */}
              <textarea
                placeholder="Enter a caption"
                value={caption}
                onChange={(e) => setCaption(e.target.value)}
              />
              
              {/* Submit button */}
              <button onClick={handleSubmit} disabled={uploading}>
                {uploading ? 'Uploading...' : 'Submit'}
              </button>

              {/* Cancel/close button */}
              <button onClick={closeModal} disabled={uploading}>Cancel</button>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
}

export default UploadForm;

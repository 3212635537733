import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import './PhotoSlideshow.css';

function PhotoSlideshow() {
  const { eventId } = useParams();
  const [photos, setPhotos] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const intervalRef = useRef(null);

  useEffect(() => {
    // Fetch photos for the event
    fetch(`${process.env.REACT_APP_API_URL}/photos/${eventId}`)
      .then(response => response.json())
      .then(data => {
        setPhotos(data);
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Error fetching photos:', error);
        setIsLoading(false);
      });
  });

  useEffect(() => {
    if (photos.length > 0 && !intervalRef.current) {
      intervalRef.current = setInterval(() => {
        setIsTransitioning(true);
        setTimeout(() => {
          setCurrentIndex((prevIndex) => (prevIndex + 1) % photos.length);
          setIsTransitioning(false);
          clearInterval(intervalRef.current);
          delete intervalRef.current;
        }, 500); // Match the CSS transition duration
      }, 3000); // 5-second interval

      // return () => clearInterval(interval);
    }
  }, [photos]);

  const handleNextSlide = () => {
    if (!isTransitioning) {
      setIsTransitioning(true);
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % photos.length);
        setIsTransitioning(false);
      }, 1000); // Match this duration with CSS animation duration
    }
  };

  // const handlePreviousSlide = () => {
  //   if (!isTransitioning) {
  //     setIsTransitioning(true);
  //     setTimeout(() => {
  //       setCurrentIndex((prevIndex) => (prevIndex - 1 + photos.length) % photos.length);
  //       setIsTransitioning(false);
  //     }, 1000); // Match this duration with CSS animation duration
  //   }
  // };

  const handlePreviousSlide = () => {
    if (!isTransitioning) {
      setIsTransitioning(true);
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + photos.length) % photos.length);
        setIsTransitioning(false);
      }, 1000);  // Match with CSS animation time
    }
  };

  const handleManualNextSlide = () => {
    clearInterval(intervalRef.current);  // Clear the interval if the user manually navigates
    setIsTransitioning(true);
    setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % photos.length);
      setIsTransitioning(false);
    }, 500);
    intervalRef.current = setInterval(() => {
      handleNextSlide();
    }, 3000);  // Restart interval
  };

  const handleManualPreviousSlide = () => {
    clearInterval(intervalRef.current);  // Clear the interval if the user manually navigates
    setIsTransitioning(true);
    setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex - 1 + photos.length) % photos.length);
      setIsTransitioning(false);
    }, 1000);
    intervalRef.current = setInterval(() => {
      handleNextSlide();
    }, 3000);  // Restart interval
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (photos.length === 0) {
    return <div>No photos available for this event.</div>;
  }

  return (
    <div className="slideshow-container">
      <div className={`slide ${isTransitioning ? 'fade-out' : 'fade-in'}`}>
        <img
          src={photos[currentIndex]?.url}
          alt={`Slide ${currentIndex}`}
          className="slide-image"
        />
      </div>
      <button className="prev" onClick={handleManualPreviousSlide}>&#10094;</button>
      <button className="next" onClick={handleManualNextSlide}>&#10095;</button>
    </div>
  );
}

export default PhotoSlideshow;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.events-section {
  padding: 60px 20px;
  text-align: center;
  background-color: #fdf6e4;
}

.events-section h2 {
  font-family: 'Gagalin', sans-serif;
  color: #042039;
}
.events-grid {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.event-card {
  width: 350px;
  margin: 20px;
}

.event-card img {
  width: 100%;
  border-radius: 10px;
}

.event-card h3 {
  font-size: 18px;
  margin-top: 10px;
  color: #042039;
}
`, "",{"version":3,"sources":["webpack://./src/EventsShowcase.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE,kCAAkC;EAClC,cAAc;AAChB;AACA;EACE,aAAa;EACb,6BAA6B;EAC7B,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;AAChB","sourcesContent":[".events-section {\n  padding: 60px 20px;\n  text-align: center;\n  background-color: #fdf6e4;\n}\n\n.events-section h2 {\n  font-family: 'Gagalin', sans-serif;\n  color: #042039;\n}\n.events-grid {\n  display: flex;\n  justify-content: space-around;\n  flex-wrap: wrap;\n}\n\n.event-card {\n  width: 350px;\n  margin: 20px;\n}\n\n.event-card img {\n  width: 100%;\n  border-radius: 10px;\n}\n\n.event-card h3 {\n  font-size: 18px;\n  margin-top: 10px;\n  color: #042039;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
